<template>
  <div class="CRMcontent">
    <div class="left">
      <p class="title" style="margin-top: 2vh;" :class="idx==0?'xz':''" @click="teb(0)">眼健康报告</p>
      <p class="title" :class="idx==1?'xz':''" @click="teb(1)">电子病历</p>
      <p class="title" :class="idx==2?'xz':''" @click="teb(2)">角膜接触镜报告</p>
      <p class="title" :class="idx==3?'xz':''" @click="teb(3)">视觉训练报告</p>
      <p class="title" :class="idx==4?'xz':''" @click="teb(4)">框架眼镜验配报告</p>
      <el-button type="primary" class="dys" @click="dy">打印</el-button>
      <div class="sz" @click="dialogVisible=true">
        <i class="el-icon-setting"></i>
        <p>手机查阅设置</p>
      </div>
    </div>

    <div class="right">
      <iframe class="znr" id="dys" ref="print" :src="src" frameborder="0"></iframe>
    </div>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%">
      <div class="isck">
        <p>顾客手机端查阅眼健康分析报告：关</p>
        <el-switch
            class="kg"
            @change="bgp"
            v-model="bg"
            active-color="#13ce66"
            inactive-color="#ff4949">
        </el-switch>
        <p>开</p>
      </div>
      <div class="isck">
        <p>顾客手机端查阅眼健康检查结果：关</p>
        <el-switch
            class="kg"
            @change='blbg'
            v-model="bl"
            active-color="#13ce66"
            inactive-color="#ff4949">
        </el-switch>
        <p>开</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CRMcontent",
  data() {
    return {
      dialogVisible: false,
      value: false,
      src: '',
      bg: 0,
      bl: 0,
      idx: 0
    }
  },
  watch: {

  },
  created() {

    this.cshTime('eyesHealthReport')
    let src = this._getUrl('CustomerReport')
    this._api.publicApi.getBlBg()
    .then(res => {
      if (res.GetResult) {
        this.$store.commit('users/upCsObj', JSON.parse(JSON.stringify(res.GetResult)))
        this.bg = Boolean(this.$store.state.users.csObj.ChoosebShowClinicRecordToCustomer)
        this.bl = Boolean(this.$store.state.users.csObj.ChoosebShowReportToCustomer)
      }
    })
    src = src+`ChooseId=${this.$store.state.physicianVisits.xzConsulting}&CustomerId=${this.$store.state.users.CsUser.CustomerId}`
    this.src = src
  },
  methods: {
    dy() {
      this.src = this.src + "&print=1"
      // let ifs = document.getElementById('dys')
      // this.$print(this.$refs.print, {
      //   maxWidth: 1920,
      //   iframe: true
      // })

      // return
      // let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.eyesHealthReport.ENTER)/1000 + Number(this.$store.state.Time.timeObj.eyesHealthReport.EXIT)
      // this._api.publicApi.addTime('eyesHealthReport', times)
      // this.jsTime('eyesHealthReport', 1)


    },
    teb (idx) {
      if (idx == this.idx) return
      this.idx = idx
      let src = this._getUrl('CustomerReport')
      let cs = this.$store.state.users.CsUser.CustomerId,
          ch = this.$store.state.physicianVisits.xzConsulting,
          spid = this.$store.state.bases.shop.ShopId
      switch (idx) {
        case 0:
          src = src+`ChooseId=${ch}&CustomerId=${cs}`
          break
        case 1:
          if (!this.$store.state.users.employees) return this.$alert('员工未登录，请登录！', '提示').then(res => this.$router.push({path: '/employeesLanding'}))
          let exid = this.$store.state.users.employees.ExpertId
          src = this._getUrl('ChooseReport_MR') + `CustomerId=${this.$store.state.users.CsUser.CustomerId}&ChooseId=${this.$store.state.physicianVisits.xzConsulting}&ExpertId=${exid}`
          break
        case 2:
          src = this._getUrl('RGPReport')+`ChooseID=${ch}&CustomerID=${cs}`
            console.log(src)
              break
        case 3:
          src = `https://weixin.visumall.com/customerorder/TopeyeSolution?ShopId=${spid}&CustomerId=${cs}`
          // console.log(src)
          break
        case 4:
          src = this._getUrl('CentralizedReport') + `ChooseId=${ch}&CustomerId=${cs}`
      }
      this.src = src
    },
    bgp(e) {
      let is = 0
      e?is=1:is=0
      this._api.publicApi.isText('isClinicRecordCanShare', is)
    },
    blbg(e) {
      let is = 0
      e?is=1:is=0
      this._api.publicApi.isText('isReportCanShare', is)
    },

    cshTime(keys) {
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    }
  },

  beforeDestroy() {
    if (this.$store.state.Time.timeObj.eyesHealthReport.ENTER != 0) {
      this.jsTime('eyesHealthReport')
    }
  }
}
</script>

<style scoped lang="scss">
  .CRMcontent {
    width: 100vw;
    height: 76vh;
    overflow: hidden;
    display: flex;
    justify-content: start;
  }
  .left {
    width: 10vw;
    height: 76vh;
    background: #393939;
    color: #ffffff;
    .title {
      position: relative;
      padding: .15rem 0;
      border-bottom: 2px solid #ffffff;
      //border-top: 2px solid #ffffff;
      font-size: 12px;
      cursor: pointer;
    }
    .xz {
      background: rgb(64,158,255);
      color: #FFFFFF;
    }
    .dys {margin-top: 20vh;}
    .sz {
      cursor: pointer;
      margin-top: 10vh;
      i {font-size: 30px}
    }
  }
  .isck {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: .2rem;
    .kg {margin: 0 .15rem;}
  }

  .right {
    width: 90vw;
    height: 76vh;
    overflow: auto;
    -ms-overflow-style: none;
    .znr {
      width: 100%;
      height: 100%;
      zoom: 80%;
    }
  }
  .right::-webkit-scrollbar { width: 0 !important }

  .bt {
    font-size: 16px;
    padding: 2vh;
  }
</style>
